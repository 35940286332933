import { RoleType } from '~/src/service/model/user';

const product: AuthRoute.Route = {
  name: 'product',
  path: '/product',
  component: 'basic',
  meta: { title: '产品管理', icon: 'mdi:menu', permissions: [RoleType.admin] },
  children: [
    {
      name: 'product_index',
      path: '/product/index',
      component: 'self',
      meta: { title: '产品列表', icon: 'material-symbols:shopping-bag' }
    }
  ]
};

export default product;
