import { defineStore } from 'pinia';
import { fetchModels } from '~/src/service/api/model';
import type { Model } from '~/src/service/model/model';
import { fetchDrawConfig, fetchGlobalConfig } from '~/src/service/api/admin-config';

interface ConfigState {
  models: Model[];
  drawConfig?: {
    point: number;
  }
	globalConfig: {
		model_description: string
		invite_award_point: number
	} | undefined
}

export const useConfigStore = defineStore('config-store', {
  state: (): ConfigState => ({
    models: [],
    drawConfig: undefined,
		globalConfig: undefined
  }),
  actions: {
    async initConfig() {
      await this.getModels();
      await this.initDrawConfig();
    },

		async initGlobalConfig() {
			const { data, error } = await fetchGlobalConfig()
			if (error) return
			this.globalConfig = data as any
		},


    async getModels() {
      const { data, error } = await fetchModels();
      if (error) return;
      this.models = data.map(item => {
        // @ts-expect-error
        item.label = item.name;
        return item;
      });
    },
    async initDrawConfig() {
      fetchDrawConfig().then(res => {
        if (res.error) return;
        this.drawConfig = res.data as any;
      });
    }
  }
});
