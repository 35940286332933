import { getLoginModuleRegExp } from '@/utils';

/** 根路由: / */
export const ROOT_ROUTE: AuthRoute.Route = {
  name: 'root',
  path: '/',
  redirect: import.meta.env.VITE_ROUTE_HOME_PATH,
  meta: {
    title: 'Root'
  }
};

export const feRoutes: AuthRoute.Route[] = [
  {
    name: 'chat',
    path: '/chat',
    component: 'self',
    meta: { title: '聊天', keepAlive: true, icon: 'ph:chats', singleLayout: 'basic', requiresAuth: false, order: 1 }
  },
  {
    name: 'draw',
    path: '/draw',
    component: 'self',
    meta: { title: '绘画', keepAlive: true, icon: 'mdi:draw', singleLayout: 'basic', requiresAuth: false, order: 2 }
  },

  {
    name: 'app',
    path: '/app',
    component: 'basic',
    redirect: '/app/index',
    meta: { title: '应用', keepAlive: true, icon: 'ph:app-store-logo-bold', requiresAuth: false, order: 3 },
    children: [
      {
        name: 'app_index',
        path: '/app/index',
        component: 'self',
        meta: { title: '应用', icon: 'ph:app-store-logo-bold' }
      },
      {
        name: 'app_prompt',
        // @ts-expect-error
        path: '/app/prompt/:id',
        component: 'self',
        meta: { title: '应用提示', icon: 'mdi:menu', hide: true }
      }
    ]
  },
  {
    name: 'gallery',
    path: '/gallery',
    component: 'self',
    meta: { title: '画廊', keepAlive: false, icon: 'mdi:draw', singleLayout: 'basic', requiresAuth: false, order: 4 }
  },
  {
    name: 'flow',
    path: '/flow',
    component: 'basic',
    redirect: '/flow/list',
    meta: { title: '工作流', keepAlive: true, icon: 'ph:app-store-logo-bold', requiresAuth: false, order: 3 },
    children: [
      {
        name: 'flow_list',
        path: '/flow/list',
        component: 'self',
        meta: { title: '工作流', icon: 'ph:activity-bold' }
      },
      {
        name: 'flow_run',
        // @ts-expect-error
        path: '/flow/run/:id',
        component: 'self',
        meta: { title: '工作流应用', icon: 'ph:activity-bold', requiresAuth: true, hide: true }
      },
      {
        name: 'flow_template',
        path: '/flow/template',
        component: 'self',
        meta: { title: '工作流模板', icon: 'ph:app-store-logo-bold', requiresAuth: true, hide: true }
      },
      {
        name: 'flow_detail',
        // @ts-expect-error
        path: '/flow/detail/:id',
        component: 'self',
        meta: { title: '工作流编辑', icon: 'mdi:menu', requiresAuth: true, hide: true }
      },
			{
        name: 'flow_gpts_id',
        // @ts-expect-error
        path: '/flow/gpts/:id',
        component: 'self',
        meta: { title: 'GPTs运行', icon: 'mdi:menu', requiresAuth: true, hide: true }
      },
      {
        name: 'flow_create',
        path: '/flow/create',
        component: 'self',
        meta: { title: '工作流创建', icon: 'mdi:menu', requiresAuth: true, hide: true }
      }
    ]
  },
	{
    name: 'knowledge',
    path: '/knowledge',
		redirect: '/knowledge/list',
    component: 'basic',
    meta: { title: '知识库', keepAlive: true, icon: 'mdi:draw', requiresAuth: true, order: 5, hide: true },
		children: [
      {
        name: 'knowledge_list',
        path: '/knowledge/list',
        component: 'self',
        meta: { title: '知识库', icon: 'material-symbols:library-books-outline' }
      },
			{
        name: 'knowledge_detail',
				// @ts-expect-error
        path: '/knowledge/detail/:sid',
        component: 'self',
        meta: { title: '知识库详情', icon: 'ph:app-store-logo-bold', hide: true }
      },
		]
  },
	// {
  //   name: 'robots',
  //   path: '/robots',
  //   component: 'basic',
  //   redirect: '/robots/list',
  //   meta: { title: 'GPTs', keepAlive: true, icon: 'ph:app-store-logo-bold', requiresAuth: false, order: 4 },
  //   children: [
  //     {
  //       name: 'robots_list',
  //       path: '/robots/list',
  //       component: 'self',
  //       meta: { title: 'GPTs', icon: 'ph:activity-bold' }
  //     },

  //   ]
  // },
  // {
  // 	name: 'flow',
  // 	path: '/flow',
  // 	component: 'self',
  // 	meta: { title: '工作流', keepAlive: true, icon: 'mdi:draw', singleLayout: 'basic', requiresAuth: false, order: 5 }
  // },
  {
    name: 'me',
    path: '/me',
    component: 'basic',
    meta: { title: '我的', icon: 'ic:round-account-circle', requiresAuth: true, order: 6 },
    children: [
      {
        name: 'me_account',
        path: '/me/account',
        component: 'self',
        meta: { title: '账户概览', icon: 'ic:round-account-circle' }
      },
      {
        name: 'me_billing',
        path: '/me/billing',
        component: 'self',
        meta: { title: '我的账单', icon: 'teenyicons:float-right-outline' }
      },
      {
        name: 'me_product',
        path: '/me/product',
        component: 'self',
        meta: { title: '购买套餐', icon: 'mdi:diamond' }
      },
      {
        name: 'me_invite',
        path: '/me/invite',
        component: 'self',
        meta: { title: '邀请好友', icon: 'tabler:friends' }
      }
    ]
  }
];

/** 固定的路由 */
export const constantRoutes: AuthRoute.Route[] = [
  ROOT_ROUTE,
  ...feRoutes,
  {
    name: 'login',
    path: '/login',
    component: 'self',
    props: route => {
      const moduleType = (route.params.module as UnionKey.LoginModule) || 'pwd-login';
      return {
        module: moduleType
      };
    },
    meta: {
      title: '登录',
      dynamicPath: `/login/:module(${getLoginModuleRegExp()})?`,
      singleLayout: 'blank'
    }
  },

  {
    name: 'robot_:sid',
    path: '/robot/:sid',
    component: 'self',
    meta: {
      title: 'GPTs',
      singleLayout: 'blank'
    }
  },
	{
    name: 'gpts_:sid',
    path: '/gpts/:sid',
    component: 'self',
    meta: {
      title: 'GPTs',
      singleLayout: 'blank'
    }
  },
  {
    name: '403',
    path: '/403',
    component: 'self',
    meta: {
      title: '无权限',
      singleLayout: 'blank'
    }
  },
  {
    name: '404',
    path: '/404',
    component: 'self',
    meta: {
      title: '未找到',
      singleLayout: 'blank'
    }
  },
  {
    name: '500',
    path: '/500',
    component: 'self',
    meta: {
      title: '服务器错误',
      singleLayout: 'blank'
    }
  },
  // 匹配无效路径的路由
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: 'blank',
    meta: {
      title: '未找到',
      singleLayout: 'blank'
    }
  }
];
