import { RoleType } from '~/src/service/model/user';

const siteMenu: AuthRoute.Route = {
  name: 'site-menu',
  path: '/site-menu',
  component: 'basic',
  meta: { title: '站点设置', icon: 'ic:baseline-settings', order: 103, permissions: [RoleType.admin] },
  children: [
    {
      name: 'site-menu_global-config',
      path: '/site-menu/global-config',
      component: 'self',
      meta: { title: '全局设置', icon: 'ic:baseline-settings' },
      children: []
    }
  ]
};

export default siteMenu;
