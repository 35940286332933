import { RoleType } from '~/src/service/model/user';

const exchangeCode: AuthRoute.Route = {
  name: 'exchange-code',
  path: '/exchange-code',
  component: 'basic',
  meta: { title: '兑换码管理', icon: 'ic:round-edit-location-alt', order: 102, permissions: [RoleType.admin] },
  children: [
    {
      name: 'exchange-code_list',
      path: '/exchange-code/list',
      component: 'self',
      meta: { title: '兑换码列表', icon: 'icon-park-outline:exchange-four' }
    }
  ]
};

export default exchangeCode;
