import { localStg } from '@/utils';
import { RoleType } from '~/src/service/model/user';

/** 获取token */
export function getToken() {
  return localStg.get('token') || '';
}

/** 获取用户信息 */
export function getUserInfo() {
  const emptyInfo: Auth.UserInfo = {
    id: -1,
    username: '',
    role_type: RoleType.user,
    email: '',
    avatar: '',
    invite_code: '',
    billing: undefined,
    product_type: undefined,
    product_type_model: []
  };
  const userInfo: Auth.UserInfo = localStg.get('userInfo') || emptyInfo;

  return userInfo;
}

/** 去除用户相关缓存 */
export function clearAuthStorage() {
  localStg.remove('token');
  localStg.remove('refreshToken');
  localStg.remove('userInfo');
}
