import { createGlobalState } from '@vueuse/core';
import { io } from 'socket.io-client';
import { BASE_URL } from '~/src/service/request';
import { localStg } from '~/src/utils';

export const useSocket = createGlobalState(() => {
  const socket = io(BASE_URL, {
    transports: ['websocket', 'polling'],
    timeout: 5000,
    auth: (cb: any) => {
      return cb({ token: localStg.get('token') });
    }
  });

	socket.on('exception', (params) => {
		window.$message?.error(params.message)
	})

  function refresh() {
    // 如果已连接，则先断开连接
    if (socket.connected) {
      socket.disconnect();
    }

    // 重新连接并发送认证信息
    socket.connect();
    socket.auth;
  }

  return {
    socket,
    refresh
  };
});
