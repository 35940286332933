import { request } from '../request';

export function fetchSiteConfig() {
  return request.get('/v1/admin-config/site-config');
}

export function fetchUpdateSiteConfig(data: any) {
  return request.post('/v1/admin-config/site-config', data);
}

export function fetchSmtpConfig() {
  return request.get('/v1/admin-config/smtp-config');
}

export function fetchUpdateSmtpConfig(data: any) {
  return request.post('/v1/admin-config/smtp-config', data);
}

export function fetchInviteConfig() {
  return request.get('/v1/admin-config/invite-config');
}

export function fetchInviteSiteConfig(data: any) {
  return request.post('/v1/admin-config/invite-config', data);
}

export function fetchPayConfig() {
  return request.get('/v1/admin-config/pay-config');
}

export function fetchUpdatePayConfig(data: any) {
  return request.post('/v1/admin-config/pay-config', data);
}

export function fetchConversationConfig() {
  return request.get('/v1/admin-config/conversation-config');
}

export function fetchUpdateConversationConfig(data: any) {
  return request.post('/v1/admin-config/conversation-config', data);
}

export function fetchLoginConfig() {
  return request.get('/v1/admin-config/login-config');
}

export function fetchUpdateLoginConfig(data: any) {
  return request.post('/v1/admin-config/login-config', data);
}

export function fetchNoticeConfig() {
  return request.get('/v1/admin-config/notice-config');
}

export function fetchUpdateNoticeConfig(data: any) {
  return request.post('/v1/admin-config/notice-config', data);
}

export function fetchApplicationConfig() {
  return request.get('/v1/admin-config/application-config');
}

export function fetchUpdateApplicationConfig(data: any) {
  return request.post('/v1/admin-config/application-config', data);
}

export function fetchDrawConfig() {
  return request.get('/v1/admin-config/draw-config');
}

export function fetchUpdateDrawConfig(data: any) {
  return request.post('/v1/admin-config/draw-config', data);
}

export function fetchGlobalConfig() {
	return request.get('/v1/admin-config/global-config')
}
