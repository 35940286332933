import { mockRequest, request } from '../request';

/**
 * 获取验证码
 * @param phone - 手机号
 * @returns - 返回boolean值表示是否发送成功
 */
export function fetchSmsCode(phone: string) {
  return mockRequest.post<boolean>('/getSmsCode', { phone });
}

export function fetchEmailCode(email: string) {
  return request.get('/v1/auth/send-verify-code', {
    params: { email }
  });
}

export function fetchRegister(data: { email: string; password: string; verify_code: string; invite_code?: string }) {
  return request.post('/v1/auth/register', data);
}

export function fetchResetPassword(data: { email: string; password: string; verify_code: string }) {
  return request.post('/v1/auth/reset-password', data);
}

/**
 * 登录
 * @param userName - 用户名
 * @param password - 密码
 */
export function fetchLogin(email: string, password: string) {
  return request.post<ApiAuth.Token>('/v1/auth/login-by-password', { email, password });
}

/** 获取用户信息 */
export function fetchUserInfo() {
  return request.get<ApiAuth.UserInfo>('/v1/user/current');
}

/**
 * 获取用户路由数据
 * @param userId - 用户id
 * @description 后端根据用户id查询到对应的角色类型，并将路由筛选出对应角色的路由数据返回前端
 */
export function fetchUserRoutes(userId: string) {
  return mockRequest.post<ApiRoute.Route>('/getUserRoutes', { userId });
}

/**
 * 刷新token
 * @param refreshToken
 */
export function fetchUpdateToken(refreshToken: string) {
  return mockRequest.post<ApiAuth.Token>('/updateToken', { refreshToken });
}
