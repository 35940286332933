import { RoleType } from '~/src/service/model/user';

const order: AuthRoute.Route = {
  name: 'order',
  path: '/order',
  component: 'basic',
  meta: { title: '订单管理', icon: 'mdi:menu', permissions: [RoleType.admin] },
  children: [
    {
      name: 'order_index',
      path: '/order/index',
      component: 'self',
      meta: { title: '订单列表', icon: 'material-symbols:shopping-cart' }
    }
  ]
};

export default order;
