import type { RouteComponent } from 'vue-router';

export const views: Record<
  PageRoute.LastDegreeRouteKey,
  RouteComponent | (() => Promise<{ default: RouteComponent }>)
> = {
  403: () => import('./_builtin/403/index.vue'),
  404: () => import('./_builtin/404/index.vue'),
  500: () => import('./_builtin/500/index.vue'),
  robot: () => import('./robot/index.vue'),
  'robot_:sid': () => import('./robot/index.vue'),
  login: () => import('./_builtin/login/index.vue'),
  'not-found': () => import('./_builtin/not-found/index.vue'),
  app: () => import('./flow/app/index.vue'),
  app_index: () => import('./flow/app/index.vue'),
  app_prompt: () => import('./app/prompt/index.vue'),
  chat: () => import('./chat/index.vue'),
  'conversation-config_conversation': () => import('./conversation-manger/conversation/index.vue'),
  'conversation-config_endpoint': () => import('./conversation-manger/endpoint/index.vue'),
  'conversation-config_sensitive-dict': () => import('./conversation-manger/sensitive-dict/index.vue'),
  'conversation-config_model': () => import('./conversation-manger/model/index.vue'),
  'conversation-config_file': () => import('./conversation-manger/file/index.vue'),
  draw: () => import('./draw/index.vue'),
  me_account: () => import('./me/account/index.vue'),
  me_billing: () => import('./me/billing/index.vue'),
  me_invite: () => import('./me/invite/index.vue'),
  me_product: () => import('./me/product/index.vue'),
  product: () => import('./product/index.vue'),
  order: () => import('./order/index.vue'),
  product_index: () => import('./product/index.vue'),
  order_index: () => import('./order/index.vue'),
  'site-menu_global-config': () => import('./site-menu/global-config/index.vue'),
  'user-menu_user-list': () => import('./user-menu/user-list/index.vue'),
  'user-menu_invite-list': () => import('./user-menu/invite-list/index.vue'),
  'draw-manage': () => import('./draw-manage/midjourney/index.vue'),
  'draw-manage_midjourney': () => import('./draw-manage/midjourney/index.vue'),
  'draw-manage_draw-record': () => import('./draw-manage/draw-record/index.vue'),
  'message-manage': () => import('./message-manage/list/index.vue'),
  'message-manage_list': () => import('./message-manage/list/index.vue'),
  gallery: () => import('./gallery/index.vue'),
  flow: () => import('./flow/list/index.vue'),
  flow_list: () => import('./flow/list/index.vue'),
  flow_detail: () => import('./flow/detail/index.vue'),
  flow_gpts_id: () => import('./flow/gpts/index.vue'),
  flow_create: () => import('./flow/detail/index.vue'),
  flow_run: () => import('./flow/run/index.vue'),
  flow_template: () => import('./flow/template/index.vue'),
  'exchange-code': () => import('./exchange-code/index.vue'),
  'exchange-code_list': () => import('./exchange-code/index.vue'),
  statistics: () => import('./statistics/index.vue'),
  statistics_list: () => import('./statistics/index.vue'),
	knowledge: () => import('./knowledge/list/index.vue'),
	knowledge_list: () => import('./knowledge/list/index.vue'),
	knowledge_detail: () => import('./knowledge/detail/index.vue'),
  robots: () => import('./robots/index.vue'),
  robots_list: () => import('./robots/index.vue'),
	'gpts_:sid': () => import('./gpts/index.vue'),
};
