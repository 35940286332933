import { RoleType } from '~/src/service/model/user';

const statistics: AuthRoute.Route = {
  name: 'statistics',
  path: '/statistics',
  component: 'basic',
  children: [
    {
      name: 'statistics_list',
      path: '/statistics/list',
      component: 'self',
      meta: {
        title: '数据统计',
        requiresAuth: true,
        icon: 'ic:baseline-insert-chart-outlined'
      },
      children: []
    }
  ],
  meta: { title: '数据统计', icon: 'ic:baseline-insert-chart-outlined', order: 101, permissions: [RoleType.admin] }
};

export default statistics;
