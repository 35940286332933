import type { Model } from '../model/model';
import { request } from '../request';

export function fetchModels() {
  return request.get<Model[]>('/v1/model');
}

export function fetchCreateModel(data: Partial<Model>) {
  return request.post('/v1/model', data);
}

export function fetchDeleteModel(value: string) {
  return request.delete('/v1/model/', {
    params: { value }
  });
}
