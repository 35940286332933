import { RoleType } from '~/src/service/model/user';

const conversationConfig: AuthRoute.Route = {
  name: 'message-manage',
  path: '/message-manage',
  component: 'basic',
  meta: { title: '消息管理', icon: 'ic:round-edit-location-alt', order: 102, permissions: [RoleType.admin] },
  children: [
    {
      name: 'message-manage_list',
      path: '/message-manage/list',
      component: 'self',
      meta: { title: '消息列表', icon: 'material-symbols:android-messages' }
    }
  ]
};

export default conversationConfig;
