import { RoleType } from '~/src/service/model/user';

const userMenu: AuthRoute.Route = {
  name: 'user-menu',
  path: '/user-menu',
  component: 'basic',
  children: [
    {
      name: 'user-menu_user-list',
      path: '/user-menu/user-list',
      component: 'self',
      meta: {
        title: '用户列表',
        requiresAuth: true,
        icon: 'material-symbols:account-box-sharp'
      },
      children: []
    },
		{
      name: 'user-menu_invite-list',
      path: '/user-menu/invite-list',
      component: 'self',
      meta: {
        title: '邀请列表',
        requiresAuth: true,
        icon: 'material-symbols:account-box-sharp'
      },
      children: []
    }
  ],
  meta: { title: '用户管理', icon: 'material-symbols:account-box-sharp', order: 101, permissions: [RoleType.admin] }
};

export default userMenu;
