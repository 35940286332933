export enum RoleType {
  user = 1,
  admin = 2
}
export interface User {
  id: number;
  email: string;
  username: string;
  points: number;
  role_type: RoleType;
  create_date: string;
  update_date: string;
}
