import { RoleType } from '~/src/service/model/user';

const conversationConfig: AuthRoute.Route = {
  name: 'conversation-config',
  path: '/conversation-config',
  component: 'basic',
  meta: { title: '对话管理', icon: 'mdi:message-check', order: 102, permissions: [RoleType.admin] },
  children: [
    {
      name: 'conversation-config_endpoint',
      path: '/conversation-config/endpoint',
      component: 'self',
      meta: { title: '接入点设置', icon: 'material-symbols:text-select-jump-to-end' }
    },
    {
      name: 'conversation-config_sensitive-dict',
      path: '/conversation-config/sensitive-dict',
      component: 'self',
      meta: { title: '敏感词库', icon: 'mdi:file-document' }
    },
    {
      name: 'conversation-config_conversation',
      path: '/conversation-config/conversation',
      component: 'self',
      meta: { title: '对话列表', icon: 'mdi:message-text' }
    },
    {
      name: 'conversation-config_model',
      path: '/conversation-config/model',
      component: 'self',
      meta: { title: '模型列表', icon: 'mdi:chart-timeline' }
    },
    {
      name: 'conversation-config_file',
      path: '/conversation-config/file',
      component: 'self',
      meta: { title: '文件列表', icon: 'mdi:file' }
    }
  ]
};

export default conversationConfig;
