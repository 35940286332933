import { RoleType } from '~/src/service/model/user';

const conversationConfig: AuthRoute.Route = {
  name: 'draw-manage',
  path: '/draw-manage',
  component: 'basic',
  meta: { title: '画图管理', icon: 'material-symbols:draw-outline', order: 102, permissions: [RoleType.admin] },
  children: [
    {
      name: 'draw-manage_midjourney',
      path: '/draw-manage/midjourney',
      component: 'self',
      meta: { title: '账号列表', icon: 'material-symbols:draw-outline' }
    },
    {
      name: 'draw-manage_draw-record',
      path: '/draw-manage/draw-record',
      component: 'self',
      meta: { title: '画图列表', icon: 'material-symbols:draw-outline' }
    }
  ]
};

export default conversationConfig;
